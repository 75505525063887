import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link } from 'react-router-dom';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const htmlToFormattedText = require("html-to-formatted-text");

const Journals = (props) => {

    const navigate = useNavigate();


    const [alljournal, setAlljournal] = useState();

    const [journal, setJournal] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAlljournal(props.journals);
        arrangeJournal(props.pubjournals);

    }, [props.journals])

    const arrangeJournal = (data) => {
        var result = data.reduce(function (r, a) {
            r[a.publishers_name] = r[a.publishers_names] || [];
            r[a.publishers_name].push(a);
            return r;
        }, Object.create(null));

        setJournal(result)
        setLoading(false)
    }

    return (
        <>
            <div id="journal" className="text-left paddsection bg-custom">

                <div className="container">
                    <div className="section-title text-center">
                        <h2> <span style={{ color: props.colors && props.colors.headlineColor }}>
                            Publications
                        </span>
                        </h2>
                    </div>
                </div>

                <div className="container">
                    <div className="journal-block">
                        {!loading &&
                            <div className="row justify-content-center">


                                {Object.keys(journal).map((publisher) => (


                                    <div className="col-lg-4 col-md-6 p-2">
                                        <Paper elevation={3} >
                                            <div className="journal-info custom-height">


                                                <div className="journal-txt p-3">
                                                    {/* <VerifiedUserIcon /> */}
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span className='text-start'> <h4>
                                                                {publisher} </h4></span>
                                                        </div>
                                                        <div className="col-6">

                                                            {props.publishers && props.publishers.map((el) => (
                                                                <>
                                                                    {
                                                                        el.publishers_name === publisher &&
                                                                        <div className='text-end text-success'> <strong> <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${el.logo}`} height={20} alt="img" /> </strong>
                                                                        </div>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <p className="separator"> <AccountCircleIcon /> Angana
                                                    </p>
                                                    <Divider color="dark" />
                                                </div>

                                                {alljournal.map((el) => (
                                                    <>
                                                        {
                                                            el.publishers_name === publisher &&
                                                            <div className='p-3'>
                                                                <div className='row'>
                                                                    <div className="col-8 text-small" style={{ textAlign: 'justify' }}>
                                                                        <div> <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> <strong> <span style={{ color: props.colors && props.colors.titleColor }}> {el.title.slice(0, 60)}...</span></strong></Link></div>
                                                                        <span className="text-small text-secondary">{htmlToFormattedText(el.description).slice(0, 90)}... <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}>Read More..</Link></span>
                                                                    </div>
                                                                    <div className="col-4">

                                                                        <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}><img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.image}`} className="img-responsive" alt="img" /></Link>
                                                                    </div>
                                                                </div>
                                                                <Divider />
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                            </div>
                                        </Paper>
                                    </div>
                                ))}

                            </div>

                            
                        }

                        <div className='text-center m-2'> <Button variant='outlined' size='small' onClick={()=> navigate('allpublisher')}>View More Publications</Button> </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Journals;