import React from 'react'

import Nav2 from '../components/common/navbar'
import AllPublisher from '../components/allpublisher/allpublisher'
import Footer from '../components/common/footer'


const AllpublisherPage = () => {
    return (
        <>
            <Nav2 />
            <AllPublisher />
            <Footer />
        </>
    )
}

export default AllpublisherPage;