import React, { useEffect, useState } from 'react'
import ViewArticle from '../components/viewArticle/viewArticle';

import { useParams } from 'react-router-dom';
import api from '../API/api';

import Navbar from '../components/common/nav';
import Nav2 from '../components/common/navbar'
import Footer from '../components/common/footer'

const ViewArticlePage = () => {

    const params = useParams();

    const [journal, setJournal] = useState();
    const [related, setRelated] = useState();

    useEffect(() => {
        console.log("id=>>", params.id);
        getArticle();
    }, []);

    const getArticle = () => {
        api.getArticle(params.id)
            .then((res) => {
                console.log(res);
                setJournal(res.data.journal);
                setRelated(res.data.related);
            })
            .catch((err) => {
                console.log(err.response);
            })

    }




    return (
        <>
            <Nav2 />
            <ViewArticle journal={journal} related={related} />
            <Footer/>
        </>
    )
}

export default ViewArticlePage;