import React, { useState, useEffect, useContext } from 'react'
// import Navbar from '../components/common/nav'
import Nav2 from '../components/common/navbar'
import Hero from '../components/home/hero'
import About from '../components/home/about'
import Journals from '../components/home/journal'
import Latest from '../components/home/latest'
import Category from '../components/home/category'
import Contact from '../components/home/contact'
import Footer from '../components/common/footer'
import api from '../API/api'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ColorContext } from '../App'



const Homepage = () => {

    const [loader, setLoader] = useState(false);
    const { colors, bio } = useContext(ColorContext);

    const [journals, setJournals] = useState([]);
    const [pubjournals, setPubJournals] = useState([]);
    const [publishers, setPublishers] = useState([]);
    const [firstCat, setFirstCat] = useState();
    const [youtube, setYoutube] = useState([]);

    useEffect(() => {
        console.log("coloor", colors);
        getJournal();
    }, []);

    const getJournal = () => {
        setLoader(true)
        api.getJournal()
            .then((res) => {
                setJournals(res.data.journals)
                setPubJournals(res.data.pub_journals)
                setPublishers(res.data.publishers)
                setFirstCat(res.data.journals[0].cat_name)
                setYoutube(res.data.youtube)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    const Loading = () => {
        return (
            <>
                <div className='container p-5'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="p-4 row">
                                <div className="col-md-6 my-2">
                                    <Skeleton variant="circular" width={200} height={200} style={{ borderRadius: '50%' }} />
                                </div>
                                <div className="col-md-6 my-2 d-flex align-items-center">

                                    <div>
                                        <Skeleton height={50} width={400} count={1} style={{ marginBottom: 6 }} />
                                        <Skeleton height={80} width={400} count={1} style={{ marginBottom: 6 }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />

                            <div className='row'>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>



            <div className=''>
                <Nav2 />
                <About bio={bio}/>
                {
                    loader ? Loading() :
                        <>
                            
                            <Latest journals={journals} youtube={youtube} colors={colors} />
                            <Journals journals={journals} pubjournals={pubjournals} publishers={publishers} colors={colors} />
                            <Category journals={journals} firstCat={firstCat} colors={colors} />
                            <Footer />
                        </>
                }
            </div>
        </>
    )
}

export default Homepage;