import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Card } from '@mui/material';

import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const htmlToFormattedText = require("html-to-formatted-text");


const Latest = (props) => {


    return (
        <>
            <div id="latest-journal" className="text-left paddsection bg-light">
                <div className="container">
                    {/* <Card> */}
                    <div className="section-title text-center">
                        <h2 className='fancy'> <span style={{ color: props.colors && props.colors.headlineColor }}> Latest Read </span></h2>
                    </div>
                    {/* </Card> */}
                </div>

                <div className="container">
                    <div className="journal-block">
                        <div className="row">
                            <div className="col-md-8">
                                <Paper elevation={3} >
                                    <div className="journal-info">

                                        {props.journals && props.journals.slice(0, 3).map((el) => (

                                            <>
                                                <div className='big-box p-3'>
                                                    <div className='row'>
                                                        <div className="col-3" >
                                                            <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}><img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.image}`} className="img-responsive border-round" alt="img" />
                                                            </Link>

                                                        </div>
                                                        <div className="col-9">
                                                            <div> <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> <strong> <span style={{ color: props.colors && props.colors.titleColor }}>{el.title}</span> </strong></Link></div>

                                                            {htmlToFormattedText(el.description).slice(0, 220)}... <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> Read More... </Link>

                                                        </div>

                                                        <div className="col-12">
                                                            <div className='text-end text-success'> <strong> <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${el.logo}`} height={20} alt="img" /> </strong> </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <div className='m-2 text-secondary align-items-center  text-small'>
                                                                        <CategoryIcon fontSize='smaller' sx={{ color: 'pink' }} /> &nbsp; {el.cat_name}
                                                                    </div>
                                                                </div>
                                                                <div className="col-8">
                                                                    <div className='m-2 text-secondary text-small'>
                                                                        <span className=' float-end mx-2'>
                                                                            <CalendarMonthIcon fontSize='smaller' />
                                                                            {
                                                                                new Date(el.date).getDate()
                                                                                +", "+
                                                                                new Date(el.date).toLocaleString("default", {
                                                                                    month: "long",
                                                                                }) +
                                                                                    "-" +
                                                                                    new Date(el.date).getFullYear()
                                                                            }
                                                                        </span>

                                                                        <span className=' float-end'>
                                                                            <AccountCircleIcon fontSize='smaller' />  Angana Chakrabarti |
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                                <Divider color="dark" />
                                            </>
                                        ))}


                                    </div>
                                </Paper>
                            </div>


                            <div className="col-md-4">


                                {/* YOUTUBE  */}
                                <Paper elevation={3} >
                                    <div className="journal-info small-box">
                                        <div className="journal-txt p-3">
                                            <h4>
                                                <span style={{ color: props.colors && props.colors.headlineColor }}> Youtube Video </span> </h4>
                                            <Divider color="dark" />
                                        </div>

                                        <Swiper
                                            spaceBetween={10}
                                            loop={false}
                                            autoplay={{
                                                delay: 8000,
                                                disableOnInteraction: false,
                                            }}
                                            speed={1500}
                                            modules={[Autoplay, Pagination, Navigation, Scrollbar]}
                                            // navigation
                                            // pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                            className="testimonials-slider swiper"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >

                                            <div className="swiper-wrapper">
                                                {props.youtube && props.youtube.map((el) =>
                                                (
                                                    <SwiperSlide>
                                                        <div className='text-center'>
                                                            <iframe width="100%" height="260" src={el.links} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </div>

                                        </Swiper>


                                    </div>
                                </Paper>

                                <div className='mt-1'>


                                </div>
                                <Paper elevation={3} >
                                    <div className="journal-info small-box">


                                        <div className="journal-txt p-3">
                                            <h4><span style={{ color: props.colors && props.colors.headlineColor }}>
                                                {/* <VerifiedUserIcon /> */}
                                                Exclusive Stories</span></h4>
                                            {/* <p className="separator"> <AccountCircleIcon /> Angana Chakrabarti
                                            </p> */}
                                            {/* <br /> */}
                                            <Divider color="dark" />
                                        </div>


                                        <Swiper
                                            spaceBetween={10}
                                            loop={false}
                                            autoplay={{
                                                delay: 8000,
                                                disableOnInteraction: false,
                                            }}
                                            speed={1500}
                                            modules={[Autoplay, Pagination, Navigation, Scrollbar]}
                                            className="testimonials-slider swiper"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >

                                            <div className="swiper-wrapper">
                                                {props.journals && props.journals.map((el) => (
                                                    <>
                                                        {el.isExclusive == 1 &&
                                                            <SwiperSlide>
                                                                <div className='p-3'>
                                                                    <div className='row'>
                                                                        <div className="col-8 text-small" style={{ textAlign: 'justify' }}>
                                                                            <div ><Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> <strong><span style={{ color: props.colors && props.colors.titleColor }}> {el.title.slice(0, 100)}... </span></strong></Link></div>
                                                                            <span className="text-small text-secondary">  {htmlToFormattedText(el.description).slice(0, 240)}... <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> Read More... </Link></span>

                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div>
                                                                                <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}><img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.image}`} className="img-responsive" alt="img" /></Link>
                                                                            </div>
                                                                            <div>
                                                                                <div className='text-end text-success'> <strong> <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${el.logo}`} height={15} alt="img" /> </strong> </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <Divider />
                                                                </div>

                                                            </SwiperSlide>
                                                        }
                                                    </>
                                                ))}
                                            </div>


                                        </Swiper>









                                    </div>
                                </Paper>
                            </div>



                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Latest