import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { Link } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import api from '../../API/api';



const htmlToFormattedText = require("html-to-formatted-text");

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        </>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    // console.log("Selected ",index);
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export default function AllPublisher(props) {


    const [journals, setJournals] = useState([]);
    const [publishers, setPublishers] = useState();

    useEffect(() => {
        getJournal();
    }, []);

    const getJournal = () => {
        setLoading(true)
        api.getJournal()
            .then((res) => {
                // console.log("==z", res);
                setJournals(res.data.journals)
                setPublishers(res.data.publishers)
                fiterCategory(res.data.journals, res.data.publishers[0].publishers_name);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err.response);
            })

    }

    const fiterCategory = (journals, publisher) => {
        var filterValue = journals.filter(el => el.publishers_name === publisher
        )
        setCatJournal(filterValue);
    }


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log("newValue", event.target.textContent);
        setValue(newValue);

        fiterCategory(journals, event.target.textContent);
    };

    const [catJournal, setCatJournal] = useState();
    const [loading, setLoading] = useState(true);


    const Loading = () => {
        return (
            <>
                <div className='container p-5'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="p-4 row">
                                <div className="col-md-6 my-2">
                                    <Skeleton variant="circular" width={200} height={200} style={{ borderRadius: '50%' }} />
                                </div>
                                <div className="col-md-6 my-2 d-flex align-items-center">

                                    <div>
                                        <Skeleton height={50} width={400} count={1} style={{ marginBottom: 6 }} />
                                        <Skeleton height={80} width={400} count={1} style={{ marginBottom: 6 }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />

                            <div className='row'>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-3 my-2">
                                    <Skeleton height={250} count={1} style={{ marginBottom: 6 }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    const CatView = () => {
        return (
            <>
                {catJournal && catJournal.map((el) => (

                    <Paper elevation={3} >
                        <div className="col-12">
                            <div className='p-3'>
                                <div className='row'>
                                    <div className="col-2">
                                        <Link to={`/article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}><img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.image}`}
                                            className='col-12'
                                        // style={{ width: '70%' }} 
                                        />
                                        </Link>
                                    </div>

                                    <div className="col-10">
                                        <div> <Link to={`/article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> <strong> <span style={{ color: props.colors && props.colors.titleColor }}> {el.title} </span></strong></Link></div>
                                        <span className="text-small text-secondary">{htmlToFormattedText(el.description).slice(0, 290)}... <Link to={`/article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}>Read More..</Link></span>
                                    </div>

                                    <div className="col-12">
                                        <div className='text-end text-success'> <strong>  <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${el.logo}`} height={20} alt="img" /> </strong> </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className='m-2 text-secondary align-items-center  text-small'>
                                                    <CategoryIcon fontSize='smaller' /> &nbsp; {el.cat_name}
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className='m-2 text-secondary text-small'>
                                                    <span className=' float-end mx-2'>
                                                        <CalendarMonthIcon fontSize='smaller' />  {
                                                            new Date(el.date).getDate()
                                                            + ", " +
                                                            new Date(el.date).toLocaleString("default", {
                                                                month: "long",
                                                            }) +
                                                            "-" +
                                                            new Date(el.date).getFullYear()
                                                        }
                                                    </span>

                                                    <span className=' float-end'>
                                                        <AccountCircleIcon fontSize='smaller' />  Angana Chakrabarti |
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <Divider color="dark" />
                        </div>
                    </Paper>
                ))}

            </>
        )
    }

    return (
        <>

            {loading ? Loading() :


                <div id="journal" className="text-left paddsection bg-light">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2> <span style={{ color: props.colors && props.colors.headlineColor }}>PUBLICATIONS </span> </h2>
                        </div>
                    </div>
                    <div className="container">

                        <Paper elevation={3} >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    // maxWidth: { xs: 320, sm: 480 },
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons
                                    aria-label="visible arrows tabs example"
                                    sx={{
                                        [`& .${tabsClasses.scrollButtons}`]: {
                                            '&.Mui-disabled': { opacity: 0.3 },
                                        },
                                    }}
                                >
                                    {!loading && publishers.map((publisher) => (

                                        <Tab label={publisher.publishers_name
                                        } />

                                    ))}

                                </Tabs>

                                {!loading && journals.map((publisher, index) => (


                                    <TabPanel value={value} index={index}>
                                        <CatView />
                                    </TabPanel>

                                ))}
                            </Box>
                        </Paper>

                    </div>
                </div>

            }
        </>
    );
}