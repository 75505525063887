import React from 'react'
import { Paper } from '@mui/material'
import { Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Card } from '@mui/material';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';

const htmlToFormattedText = require("html-to-formatted-text");

const ViewArticle = (props) => {
    return (
        <>
            <>
                <div id="journal" className="text-left paddsection bg-light">
                    <div className="container">
                        {/* <div className="section-title text-center">
                            <h2 className='fancy'> <span> Latest Read</span></h2>
                        </div> */}
                    </div>
                    <div className="container">
                        <div className="row">
                            {props.journal &&
                                <div className="col-md-8">
                                    <Paper elevation={3} >
                                        <div className="">
                                            <div className="">
                                                <h3 className='pt-4 p-3'> <> {props.journal.title}</></h3>
                                            </div>
                                            <div className="p-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {/* {
                                                            props.journal.cat_name
                                                        } */}
                                                        <Chip label={props.journal.cat_name} color='error' size="small" />
                                                        &nbsp;
                                                        <Chip label={
                                                                                new Date(props.journal.date).getDate()
                                                                                +", "+
                                                                                new Date(props.journal.date).toLocaleString("default", {
                                                                                    month: "long",
                                                                                }) +
                                                                                    "-" +
                                                                                    new Date(props.journal.date).getFullYear()
                                                                            } size="small" />
                                                        &nbsp; &nbsp;
                                                        <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${props.journal.logo}`} height={25} alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <img src={`${process.env.REACT_APP_BACKEND}assets/journal/${props.journal.image}`} className="img-responsive border-round" alt="img" width={'100%'} />


                                        <div className="m-3 mt-5 pb-3">
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: props.journal.description }} />

                                            {props.journal.type === "short" &&
                                                <div className="col-12 text-center">
                                                    <div className='full-article'>
                                                        <strong>  <a href={props.journal.link} target='_blank'> <span className='short-link'>  Read Full Article</span> </a> </strong>
                                                    </div>
                                                </div>
                                            }
                                            {props.journal.type === "full" &&
                                                <div className="disclaimer">
                                                    <strong> Originally Published on: <a href={props.journal.link} target='_blank'> {props.journal.publishers_name}({props.journal.link})</a> </strong>
                                                </div>
                                            }

                                        </div>
                                    </Paper>

                                    {/* <Paper>
                                        <div className="p-3">
                                            <div className="disclaimer">
                                                <strong> Previously Published on: <a href=""> {props.journal.publishers_name}</a> </strong>
                                            </div>
                                        </div>

                                    </Paper> */}

                                </div>
                            }

                            <div className="col-md-4">
                                {props.journal &&
                                    <Paper elevation={3} >
                                        <div className="journal-info">


                                            <div className="journal-txt p-3">
                                                <h4><a href=""><VerifiedUserIcon />Related Article</a></h4>
                                                <p className="separator"> <CategoryIcon size='small' /> {props.journal.cat_name}
                                                </p>
                                                <br />
                                                <Divider color="dark" />
                                            </div>
                                            {props.related && props.related.slice(0, 4).map((el) => (
                                                <div className='p-3'>
                                                    <div className='row'>
                                                        <div className="col-8 text-small">
                                                            <div><a href=''> <strong> {el.title.slice(0, 60)}...</strong></a></div>
                                                            <span className="text-small text-secondary">
                                                                {htmlToFormattedText(el.description).slice(0, 90)}...
                                                                <a href=''>Read More..</a></span>
                                                        </div>
                                                        <div className="col-4">
                                                            <div>
                                                                <a href="blog-single.html">
                                                                    <img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.image}`} className="img-responsive" alt="img" width={'100%'} />

                                                                </a>
                                                            </div>
                                                            <div>
                                                                <div className='text-end text-success'> <strong>
                                                                    <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${el.logo}`} height={10} alt="img" />

                                                                </strong>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <Divider />
                                                </div>
                                            ))}



                                        </div>
                                    </Paper>
                                }
                            </div>



                        </div>
                    </div>

                </div>
            </>
        </>
    )
}

export default ViewArticle