import React from 'react'

import ContactTable from '../table/contactTable'

const ContactRequest = () => {
    return (
        <>
            <ContactTable />
        </>
    )
}

export default ContactRequest