import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { Link } from 'react-router-dom';
const htmlToFormattedText = require("html-to-formatted-text");


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        </>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    // console.log("Selected ",index);
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export default function Category(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log("newValue", event.target.textContent);
        setValue(newValue);

        fiterCategory(event.target.textContent);
    };

    const [alljournal, setAlljournal] = useState();

    const [catJournal, setCatJournal] = useState();

    const [journal, setJournal] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("firstOn=>", props.journals);
        setAlljournal(props.journals);
        arrangeJournal(props.journals);
        if(props.firstCat){
            fiterCategory(props.firstCat);
        }

    }, [props.journals])

    const fiterCategory = (cat) => {
        var filterValue = props.journals.filter(el => el.cat_name == cat
        )

        console.log("filter=>", filterValue);
        setCatJournal(filterValue);
    }




    const arrangeJournal = (data) => {
        var result = data.reduce(function (r, a) {
            r[a.cat_name] = r[a.publishers_names] || [];
            r[a.cat_name].push(a);
            return r;
        }, Object.create(null));

        console.log("ccOn=", result);
        setJournal(result)
        setLoading(false)
    }


    const CatView = () => {
        return (
            <>
                {catJournal && catJournal.map((el) => (

                    <Paper elevation={3} >
                        <div className="col-12">
                            <div className='p-3'>
                                <div className='row'>
                                    <div className="col-2">
                                    <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}><img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.image}`}
                                            className='col-12'
                                        // style={{ width: '70%' }} 
                                        />
                                        </Link>
                                    </div>

                                    <div className="col-10">
                                        <div> <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}> <strong> <span  style={{color : props.colors && props.colors.titleColor }}> {el.title} </span></strong></Link></div>
                                        <span className="text-small text-secondary">{htmlToFormattedText(el.description).slice(0, 290)}... <Link to={`article/${el.title.replace(/[^A-Z0-9]+/ig, "-")}/${el.j_id}`}>Read More..</Link></span>
                                    </div>

                                    <div className="col-12">
                                        <div className='text-end text-success'> <strong>  <img src={`${process.env.REACT_APP_BACKEND}assets/publisherLogo/${el.logo}`} height={20} alt="img" /> </strong> </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className='m-2 text-secondary align-items-center  text-small'>
                                                    <CategoryIcon fontSize='smaller' /> &nbsp; {el.cat_name}
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className='m-2 text-secondary text-small'>
                                                    <span className=' float-end mx-2'>
                                                        <CalendarMonthIcon fontSize='smaller' />  {
                                                                                new Date(el.date).getDate()
                                                                                +", "+
                                                                                new Date(el.date).toLocaleString("default", {
                                                                                    month: "long",
                                                                                }) +
                                                                                    "-" +
                                                                                    new Date(el.date).getFullYear()
                                                                            }
                                                    </span>

                                                    <span className=' float-end'>
                                                        <AccountCircleIcon fontSize='smaller' />  Angana Chakrabarti |
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <Divider color="dark" />
                        </div>
                    </Paper>
                ))}

            </>
        )
    }





    return (
        <>
            <div id="journal" className="text-left paddsection bg-light">
                <div className="container">
                    <div className="section-title text-center">
                        <h2> <span  style={{color : props.colors && props.colors.headlineColor }}>News Genre </span> </h2>
                    </div>
                </div>
                <div className="container">

                    <Paper elevation={3} >
                        <Box
                            sx={{
                                flexGrow: 1,
                                // maxWidth: { xs: 320, sm: 480 },
                                bgcolor: 'background.paper',
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                aria-label="visible arrows tabs example"
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                }}
                            >
                                {!loading && Object.keys(journal).map((publisher) => (

                                    <Tab label={publisher} />

                                ))}

                            </Tabs>

                            {!loading && Object.keys(journal).map((publisher, index) => (


                                <TabPanel value={value} index={index}>
                                    <CatView />
                                </TabPanel>

                            ))}
                        </Box>
                    </Paper>

                </div>
            </div>
        </>
    );
}